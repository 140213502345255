import React from 'react'

const NoWifi = () => {
    return (
        <div
            style={{
                display: 'flex',
                height: '100vh',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <h1>Sin conexión a wifi</h1>
            <p>Intentando Reconexión</p>
            <img 
                src='./wifi-off.svg' 
                style={{
                    width: 200,
                }}
            />
        </div>
    )
}

export default NoWifi