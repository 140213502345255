import React, { useEffect, useRef, useState } from 'react';
import NoWifi from './NoWifi';

const App = () => {

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [response, setResponse] = useState('');
  const modal = useRef(0);

  const toggleModal = () => {
    const value = modal.current.style.display;
    if(value === 'flex') {
      modal.current.style.display = 'none';
    } else {
      modal.current.style.display = 'flex';
    }
  }
  

  const getInfoPinpad = async () => {
    let pinpadRes;
    let pinpadData;

    do {
      pinpadRes = await fetch('http://localhost:9000/api/integracion/getInfoPinpad');
      pinpadData = await pinpadRes.json();

      if (pinpadData.pinpadStatus !== 'en_proceso') {
        setResponse(JSON.stringify(pinpadData, null, 4));
      }

    } while (pinpadData.pinpadStatus === 'en_proceso');
  }

  const readCard = async () => {
    const fecha = Date.now();
    const reference = 'PRUEBAKIOSK' + fecha;
    let body = {
      reference,
      amount: 1,
      timeout: 30
    }

    await fetch('http://localhost:9000/api/integracion/readCard', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
	"Content-Type": "application/json"
      }
    });

    let cardRes;
    let cardData;

    do {
      cardRes = await fetch(`http://localhost:9000/api/integracion/getCardInfo/${reference}`);
      cardData = await cardRes.json();

      console.log(cardData);
      if (cardData.readCardStatus !== 'en_proceso' && cardData.readCardStatus !== 'proceso_no_iniciado') {
        setResponse(JSON.stringify(cardData, null, 4));
      }

    } while (cardData.readCardStatus === 'en_proceso' || cardData.readCardStatus === 'proceso_no_iniciado');
  }

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine)
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [navigator.onLine]);
  

  return (
    <>
      <div style={{ zIndex: 1 }}>
        {
          (isOnline) ?

            <iframe
              src='https://kiosko-cleber.bubbleapps.io/'
              className='iframe-bubble'
              id='iframe-bubble'
              tabIndex={0}
              onLoad={(e) => {
                console.log(e.target.contentWindow.focus());
              }}
              style={{
                border: 'none'
              }}
            ></iframe>
            :
            <>
              <div
                style={{ height: 70, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: 5 }}
              >
                <div
                  onClick={toggleModal}
                  style={{
                    backgroundColor: 'red',
                    borderRadius: 5,
                    color: 'white',
                    fontWeight: 'bold',
                    padding: 10,
                    cursor: 'pointer'
                  }}
                >
                  Opciones de request
                </div>
              </div>
              <NoWifi />
            </>
        }
      </div>
      <div
        ref={modal}
        style={{
          position: 'fixed',
          display: 'none',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
          left: 0,
          top: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          zIndex: 2
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: 10,
            padding: 20,
            width: 500,
            boxSizing: 'border-box'
          }}
        >
          <button style={{float: 'right'}} onClick={toggleModal}>Cerrar</button>
          <h2>Peticiones de prueba</h2>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <button onClick={getInfoPinpad}>Get Info Pinpad</button>
            <button onClick={readCard}>Read Card</button>
          </div>
          <hr/>
          <div>
            {response}
          </div>
        </div>
      </div>
    </>
  )
}

export default App;